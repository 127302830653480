import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable,of, from } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	token: any;	
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.token     =  localStorage.getItem('learner_hub_admin');
    if (this.token) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.token) });
    }

    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }
    //req = req.clone({headers:req.headers.set('Access-Control-Allow-Origin','*')});
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    return next.handle(req);
    // req = req.clone({
    //   setHeaders: {
    //     'Content-Type' : 'application/json; charset=utf-8',
    //     'Accept'       : 'application/json',
    //     'Authorization': 'Bearer '+this.token,
        
    //   },
    // });

    // return next.handle(req);
  }
}

