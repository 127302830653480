import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";



import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./auth/auth.guard";

import { AuthInterceptor } from "./auth/auth.interceptor";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { AdminComponent } from "./admin/admin.component";
// import { DashboardComponent } from "./admin/dashboard/dashboard.component";
// import { SidebarComponent } from "./admin/sidebar/sidebar.component";
// import { TopbarComponent } from "./admin/topbar/topbar.component";
// import { NotificationsComponent } from "./admin/notifications/notifications.component";
// import { LicenseesOverviewComponent } from "./admin/licensees-overview/licensees-overview.component";
// import { LicenseesAddComponent } from "./admin/licensees-add/licensees-add.component";
// import { LicenseesDetailComponent } from "./admin/licensees-detail/licensees-detail.component";
//import { LoginComponent } from './login/login.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import {
  NgbToastModule
} from "@ng-bootstrap/ng-bootstrap";
import { ToastComponent } from "./toast/toast.component";

const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'left',
			distance: 12
		},
		vertical: {
			position: 'top',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,

    // AdminComponent,
    // DashboardComponent,
    // SidebarComponent,
    // TopbarComponent,
    // NotificationsComponent,
    // LicenseesOverviewComponent,
    // LicenseesAddComponent,
    // LicenseesDetailComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ToastComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbToastModule,
    NotifierModule.withConfig(customNotifierOptions)
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
