import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ReactiveFormsModule
} from "@angular/forms";
import { JsonPipe } from "@angular/common";
import { AuthService } from "../services/auth.service";
import { LoginService } from "../services/login/login.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  loginForm: UntypedFormGroup;
  resultSet: any;
  error_message: any;
  success_message: any;
  appName: string;
  appLogo: string;
  appFavicon: string;
  imageUrl = `${environment.apiUrl}public/images/`;
  settings : any = [];
  favIcon: HTMLLinkElement = document.querySelector('#favicon');
  signup_msg: string;
  constructor(
    private router: Router,
    private authService: AuthService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private pageTitle: Title,
  ) {
    if (localStorage.getItem("admin_user_id") !== null) {
      this.router.navigate(["dashboard"]);
    } else if (localStorage.getItem("license_user_id") !== null) {
      this.router.navigate(["licensee/dashboard"]);
    } else if (localStorage.getItem("assessor_user_id") !== null) {
      this.router.navigate(["assessor/dashboard"]);
    } else if (localStorage.getItem("learner_user_id") !== null) {
      this.router.navigate(["learner/dashboard"]);
    }
    this.spinner.hide();

    this.router.routerState.root.queryParams
      .subscribe(params => {
        this.signup_msg = params['msg'];
      });

  }

  ngOnInit() {
    this.loginForm = new UntypedFormGroup({
      login_email: new UntypedFormControl(
        "",
        Validators.compose([Validators.required, Validators.email])
      ),
      password: new UntypedFormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(6)])
      )
    });

    this.appName = localStorage.getItem("app_name")
    this.appLogo = this.imageUrl + localStorage.getItem("app_logo");
    this.appFavicon = this.imageUrl + localStorage.getItem("app_favicon");

    this.favIcon.href = this.appFavicon;
    this.pageTitle.setTitle(this.appName);

    this.getAppSettings();
  }

  login(data) {
    this.spinner.show();

    this.loginService.login(data).subscribe(
      response => {
        this.resultSet = response;

        this.authService.sendToken(
          this.resultSet.response.raws.data.token.token
        );
        if (this.resultSet.response.raws.data.admin_user_id) {
          localStorage.setItem(
            "admin_user_id",
            this.resultSet.response.raws.data.admin_user_id
          );
          localStorage.setItem(
            "admin_user_pass_key",
            this.resultSet.response.raws.data.pass_key
          );
          this.router.navigate(["dashboard"]);
        } else if (this.resultSet.response.raws.data.license_user_id) {  //Licensee Redirect
          localStorage.setItem(
            "license_user_id",
            this.resultSet.response.raws.data.license_user_id
          );
          localStorage.setItem(
            "license_pass_key",
            this.resultSet.response.raws.data.license_pass_key
          );
          this.router.navigate(["licensee/dashboard"]);
        } else if (this.resultSet.response.raws.data.assessor_user_id) { //Learner Redirect
          localStorage.setItem(
            "assessor_user_id",
            this.resultSet.response.raws.data.assessor_user_id
          );
          localStorage.setItem(
            "assessor_pass_key",
            this.resultSet.response.raws.data.assessor_pass_key
          );
          this.router.navigate(["assessor/dashboard"]);
        } else if (this.resultSet.response.raws.data.learner_user_id) { //Learner Redirect
          localStorage.setItem(
            "learner_user_id",
            this.resultSet.response.raws.data.learner_user_id
          );
          localStorage.setItem(
            "learner_pass_key",
            this.resultSet.response.raws.data.learner_pass_key
          );
          this.router.navigate(["learner/mycourses"]);
        }

        localStorage.setItem(
          "avatar",
          this.resultSet.response.raws.data.avatar
        );

      },
      error => {
        this.spinner.hide();
        this.error_message = error.error.response.raws.error_message;

        setTimeout(() => {
          this.error_message = "";
        }, 60000);
      }
    );
  }

  getAppSettings() {
    var data = {};

    const headers = new HttpHeaders({
      'Accept': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("learner_hub_admin")
    });

    this.http.post(`${environment.apiUrl}getSettings`, data, { headers: headers }).subscribe(
      response => {

        if (response != undefined) {
          this.resultSet = response;
          this.settings = this.resultSet.response.raws.data;
          localStorage.removeItem("app_name");
          localStorage.setItem("app_name", this.settings['app_name']);
          localStorage.removeItem("app_email");
          localStorage.setItem("app_email", this.settings['app_email']);
          localStorage.removeItem("app_contact");
          localStorage.setItem("app_contact", this.settings['app_contact']);
          localStorage.removeItem("app_address");
          localStorage.setItem("app_address", this.settings['app_address']);
          localStorage.removeItem("app_logo");
          localStorage.setItem("app_logo", this.settings['app_logo']);
          localStorage.removeItem("app_favicon");
          localStorage.setItem("app_favicon", this.settings['app_favicon']);

          this.appName = localStorage.getItem("app_name")
          this.appLogo = this.imageUrl + localStorage.getItem("app_logo");
          this.appFavicon = this.imageUrl + localStorage.getItem("app_favicon");

          this.favIcon.href = this.appFavicon;
          this.pageTitle.setTitle(this.appName);
        } else {
          //this.searchcount =0;
        }
      },
      error => {
        if (error.status == 401) {
          localStorage.clear();
          this.router.navigate(["/login"]);
        } else {
          this.error_message = error.error.response.raws.error_message;
          setTimeout(() => {
            this.error_message = "";
          }, 3000);
        }
      }
    );
  }
}
