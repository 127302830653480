import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ReactiveFormsModule
} from "@angular/forms";
import { JsonPipe } from "@angular/common";
import { AuthService } from "../services/auth.service";
import { LoginService } from "../services/login/login.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  password: string;
  confirmPassword: string;
  resetPasswordForm: UntypedFormGroup;
  resultSet: any;
  success_message: any;
  error_message: any;

  constructor(private router: Router, private loginService: LoginService) {
    if (localStorage.getItem("admin_user_id") !== null) {
      this.router.navigate(["dashboard"]);
    } else if (localStorage.getItem("license_user_id") !== null) {
      this.router.navigate(["licensee/dashboard"]);
    } else if (localStorage.getItem("assessor_user_id") !== null) {
      this.router.navigate(["assessor/dashboard"]);
    } else if (localStorage.getItem("learner_user_id") !== null) {
      this.router.navigate(["learner/dashboard"]);
    }
  }

  ngOnInit() {
    this.resetPasswordForm = new UntypedFormGroup(
      {
        password: new UntypedFormControl(
          "",
          Validators.compose([Validators.required, Validators.minLength(6)])
        ),
        confirmPassword: new UntypedFormControl(
          "",
          Validators.compose([Validators.required, Validators.minLength(6)])
        )
      },
      {
        validators: this.checkPasswords
      }
    );
  }

  checkPasswords(formGroup: UntypedFormGroup) {
    const { value: password } = formGroup.get("password");
    const { value: confirmPassword } = formGroup.get("confirmPassword");
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  resetPassword(data) {
    //console.log('Ritesh');
    this.loginService.resetPassword(data).subscribe(
      response => {
        this.resultSet = response;

        this.success_message = this.resultSet.response.raws.success_message;
        this.router.navigate(["login"]);
      },
      error => {
        this.error_message = error.error.response.raws.error_message;

        setTimeout(() => {
          this.error_message = "";
        }, 60000);
      }
    );
  }
}
