import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
//import { DashboardComponent } from "./admin/dashboard/dashboard.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  {
    path: "signup/licensee",
    loadChildren: () => import("./signup-licensee/signup-licensee.module").then(m => m.SignupLicenseeModule)
  },
  {
    path: "signup/learner",
    loadChildren: () => import("./signup-learner/signup-learner.module").then(m => m.SignupLearnerModule)
  },
  {
    path: "signup/assessor",
    loadChildren: () => import("./signup-assessor/signup-assessor.module").then(m => m.SignupAssessorModule)
  },
  {
    path: "",
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: "",
    loadChildren: () =>
      import("./licensee/licensee.module").then(m => m.LicenseeModule),
    canActivate: [AuthGuard]
  },

  {
    path: "",
    loadChildren: () =>
      import("./learner/learner.module").then(m => m.LearnerModule),
    canActivate: [AuthGuard]
  },

  {
    path: "",
    loadChildren: () =>
      import("./assessor/assessor.module").then(m => m.AssessorModule),
    canActivate: [AuthGuard]
  },

  // canActivate: [AuthGuard]
  // {
  //   path: "licenseesdetail",loadChildren: () => import("./admin/licensees-detail/licensees-detail.module").then(
  //     m => m.LicenseesDetailModule
  //   ),
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
