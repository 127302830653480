import { Injectable, TemplateRef } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ToastService {
  success(toastMessage) {
    this.show(toastMessage, {
      classname: `bg-success text-light`,
      delay: 2000,
      autohide: true
    });
  }

  error(toastMessage) {
    this.show(toastMessage, {
      classname: `bg-danger text-light`,
      delay: 2000,
      autohide: true
    });
  }

  info(toastMessage) {
    this.show(toastMessage, {
      classname: `bg-info text-light`,
      delay: 2000,
      autohide: true
    });
  }

  warning(toastMessage) {
    this.show(toastMessage, {
      classname: `bg-warning text-light`,
      delay: 2000,
      autohide: true
    });
  }

  primary(toastMessage) {
    this.show(toastMessage, {
      classname: `bg-primary text-light`,
      delay: 2000,
      autohide: true
    });
  }

  secondary(toastMessage) {
    this.show(toastMessage, {
      classname: `bg-secondary text-light`,
      delay: 2000,
      autohide: true
    });
  }

  dark(toastMessage) {
    this.show(toastMessage, {
      classname: `bg-dark text-light`,
      delay: 2000,
      autohide: true
    });
  }

  light(toastMessage) {
    this.show(toastMessage, {
      classname: `bg-primary text-dark`,
      delay: 2000,
      autohide: true
    });
  }

  toasts: any = [];

  // Push new Toasts to array with content and options
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  // Callback method to remove Toast DOM element from view
  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
