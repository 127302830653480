<div class="bg-login">
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-6 col-lg-5 col-xl-4 mx-auto my-5">
        <div class="card card-signin my-5">
          <div class="card-body">
            <img
              src="../../assets/learneHub-logo.png"
              alt="LearnerHub logo"
              class="w-50 d-block mx-auto mb-5"
            />
            <h5 class="card-title text-center">Reset Password</h5>
            <div *ngIf="error_message">
              <p class="text-danger text-center">{{ error_message }}</p>
            </div>
            <div *ngIf="success_message">
              <p class="text-success text-center">{{ success_message }}</p>
            </div>
            <form
              [formGroup]="resetPasswordForm"
              class="form-signin"
              (ngSubmit)="resetPassword(resetPasswordForm.value)"
            >
              <div class="form-group">
                <input
                  type="password"
                  placeholder="Enter new password"
                  name="password"
                  formControlName="password"
                  class="form-control"
                  autofocus
                  required
                />
              </div>

              <div class="form-group">
                <input
                  type="password"
                  placeholder="Confirm new password"
                  name="confirmPassword"
                  formControlName="confirmPassword"
                  class="form-control"
                  autofocus
                  required
                />
                <small
                  class="text-danger"
                  *ngIf="
                    !resetPasswordForm.get('confirmPassword').errors &&
                    resetPasswordForm.hasError('passwordNotMatch') &&
                    (resetPasswordForm.get('confirmPassword').dirty ||
                      resetPasswordForm.get('confirmPassword').touched)
                  "
                >
                  Passwords do not match.
                </small>
              </div>

              <button
                class="btn btn-lg btn-secondary btn-block text-uppercase"
                type="submit"
                [disabled]="resetPasswordForm.valid == false"
              >
                Reset Password
              </button>

              <p class="text-center h5 mt-4">
                Return to
                <a
                  routerLink="/login"
                  class="h5 text-primary mb-0 font-14 font-weight-medium text-link"
                  >Sign in</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
