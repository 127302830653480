import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ReactiveFormsModule
} from "@angular/forms";
import { JsonPipe } from "@angular/common";
import { LoginService } from "../services/login/login.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  email: string;
  forgetPasswordForm: UntypedFormGroup;
  resultSet: any;
  success_message: any;
  error_message: any;
  constructor(private router: Router, private loginService: LoginService) {}

  ngOnInit() {
    this.forgetPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(
        "",
        Validators.compose([Validators.required, Validators.email])
      )
    });
  }

  sendPasswordResetLink(data) {
    //console.log('Ritesh');
    this.loginService.sendPasswordResetLink(data).subscribe(
      response => {
        this.resultSet = response;
        localStorage.setItem(
          "resetId",
          this.resultSet.response.raws.data.admin_user_id
        );

        this.success_message = this.resultSet.response.raws.success_message;
        setTimeout(() => {
          this.success_message = "";
        }, 60000);

        //this.router.navigate(["dashboard"]);
      },
      error => {
        this.error_message = error.error.response.raws.error_message;

        setTimeout(() => {
          this.error_message = "";
        }, 60000);
      }
    );
  }
}
