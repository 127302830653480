<div class="bg-login">
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-6 col-lg-5 col-xl-4 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <!--  <img
              src="../../assets/learneHub-logo.png"
              alt="LearnerHub logo"
              class="w-50 d-block mx-auto mb-5"
            /> -->
            <img
              src="{{ appLogo }}"
              alt="{{ appName }}"
              class="w-50 d-block mx-auto mb-5"
            />
            <div *ngIf="signup_msg">
              <p class="text-success text-center">{{ signup_msg }}</p>
            </div>
            <h5 class="card-title text-center">Sign In</h5>
            <div *ngIf="error_message">
              <p class="text-danger text-center">{{ error_message }}</p>
            </div>
            <div *ngIf="success_message">
              <p class="text-success text-center">{{ success_message }}</p>
            </div>
            <form
              [formGroup]="loginForm"
              class="form-signin"
              (ngSubmit)="login(loginForm.value)"
            >
              <div class="form-group">
                <input
                  type="text"
                  placeholder="sample@yahoomail.com"
                  name="username"
                  formControlName="login_email"
                  class="form-control"
                  autofocus
                  required
                />
              </div>

              <div class="form-group">
                <input
                  type="password"
                  placeholder="Password "
                  name="password"
                  formControlName="password"
                  class="form-control"
                  required
                />
              </div>
              <div
                class="d-flex justify-content-between align-items-baseline mb-3"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                  />
                  <label class="custom-control-label" for="customCheck1"
                    >Remember me</label
                  >
                </div>

                <a
                  routerLink="/forgot-password"
                  class="h5 text-link mb-0 font-14"
                  >I forgot my password</a
                >
              </div>
              <button
                class="btn btn-lg btn-secondary btn-block text-uppercase"
                type="submit"
                [disabled]="loginForm.valid == false"
              >
                Sign in
              </button>

              <div class="social-logins text-center mt-3">
                <p>Or sign in with Google</p>
                <a
                  href="/forgot-password.html"
                  class="h5 text-primary mb-0 font-14"
                  ><i class="fab fa-google"></i
                ></a>
              </div>

              <p class="text-center h5 mt-4">
                Don’t have an account?
                <a
                  href="/forgot-password.html"
                  class="h5 text-primary mb-0 font-14 font-weight-medium text-link"
                  >Sign up</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
