
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from
  '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService,
    private myRoute: Router) {
  }
  canActivate(): boolean {
    if (this.auth.isLoggednIn()) {
      console.log('isLoggednIn');
      return true;
    } else if (this.auth.isLicenseeLoggednIn()) {
      console.log('Licensee Login');
      return true;
    } else if (this.auth.isAssessorLoggednIn()) {
      console.log('Assessor Login');
      return true;
    } else if (this.auth.isLearnerLoggednIn()) {
      console.log('Learner Login');
      return true;
    } else {
      console.log('Not Loggin');
      this.myRoute.navigate(["login"]);
      return false;
    }
  }
}
