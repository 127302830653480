import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  token: any;
  objheaders: HttpHeaders = new HttpHeaders();
  options: any;
  error_message: any;
  constructor(private http: HttpClient) {
    this.objheaders = this.objheaders.append(
      "Content-Type",
      "application/json"
    );
    /*	this.objheaders = this.objheaders.append('X-Requested-With', 'XMLHttprequest');*/
    this.objheaders = this.objheaders.append(
      "Access-Control-Allow-Origin",
      "*"
    );
    this.objheaders = this.objheaders.append(
      "Access-Control-Allow-Method",
      "GET, POST"
    );
    this.objheaders = this.objheaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("learner_hub_admin")
    );
    this.options = { header: this.objheaders };
  }

  login(info) {
    var data = {};
    data["login_email"] = info.login_email;
    data["password"] = info.password;
    return this.http
      .post(environment.apiUrl + "login", data, this.options)
      .pipe();
  }

  logout(info) {
    return this.http
      .post(environment.apiUrl + "logout", info, this.options)
      .pipe();
  }

  sendPasswordResetLink(info) {
    var data = {};
    data["email"] = info.email;
    return this.http
      .post(environment.apiUrl + "forgetpassword", data, this.options)
      .pipe();
  }

  verifyPasswordResetKey(info) {
    var data = {};
    return this.http
      .post(environment.apiUrl + "verifypasscode", data, this.options)
      .pipe();
  }

  resetPassword(info) {
    var data = {};
    data["passcode"] = "P-359c5e3b";
    data["admin_user_id"] = localStorage.getItem("resetId");
    data["new_password"] = info.password;
    data["confirm_password"] = info.password;
    return this.http
      .post(environment.apiUrl + "resetpassword", data, this.options)
      .pipe();
  }
}
