import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  sendToken(token: string) {
    localStorage.setItem("learner_hub_admin", token);
  }

  getToken() {
    return localStorage.getItem("admin_user_id");
  }
  getLicenseeToken() {
    return localStorage.getItem("license_user_id");
  }

  getAssessorToken() {
    return localStorage.getItem("assessor_user_id");
  }
  getLearnerToken() {
    return localStorage.getItem("learner_user_id");
  }

  isLoggednIn() {

    if (this.getToken() !== null) {
      console.log('true');
      return true;
    } else {
      console.log('fale');
      return false;
    }

  }

  isLicenseeLoggednIn() {

    if (this.getLicenseeToken() !== null) {
      console.log('true');
      return true;
    } else {
      console.log('fale');
      return false;
    }

  }

  isAssessorLoggednIn() {

    if (this.getAssessorToken() !== null) {
      console.log('true');
      return true;
    } else {
      console.log('fale');
      return false;
    }

  }

  isLearnerLoggednIn() {

    if (this.getLearnerToken() !== null) {
      console.log('true');
      return true;
    } else {
      console.log('fale');
      return false;
    }

  }


  logout() {
    localStorage.clear();
    /*
    localStorage.removeItem("learner_hub_admin");
    localStorage.removeItem("admin_user_pass_key");
    localStorage.removeItem("admin_user_id");
    localStorage.removeItem("license_user_id");
    localStorage.removeItem("license_pass_key");
    localStorage.removeItem("learner_user_id");
    localStorage.removeItem("learner_pass_key");
    localStorage.removeItem("assessor_user_id");
    localStorage.removeItem("assessor_pass_key");
    */
    this.router.navigate(["login"]);
  }



}


/*import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private myRoute: Router) { }
  sendToken(token: string) {
    localStorage.setItem("LoggedInUser", token)
  }
  getToken() {
    return localStorage.getItem("LoggedInUser")
  }
  isLoggednIn() {
    return this.getToken() !== null;
  }
  logout() {
    localStorage.removeItem("LoggedInUser");
    this.myRoute.navigate(["Login"]);
  }
}*/
